import Components from 'formiojs/components/Components';
import Validator from 'formiojs/validator/Validator';

const FileComponent = (Components as any).components.file;

class File extends (FileComponent as any) {
    attach(element: any) {
        this.loadRefs(element, {
            fileDrop: 'single',
            fileBrowse: 'single',
            galleryButton: 'single',
            cameraButton: 'single',
            takePictureButton: 'single',
            toggleCameraMode: 'single',
            videoPlayer: 'single',
            fileLink: 'multiple',
            removeLink: 'multiple',
            fileStatusRemove: 'multiple',
            fileImage: 'multiple',
            fileType: 'multiple',
            fileProcessingLoader: 'single',
        });

        this.refs?.removeLink?.forEach((removeLink: any, index: number) => {
            this.addEventListener(removeLink, 'click', (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                const fileInfo = this.dataValue[index];

                this.deleteFile(fileInfo);
                this.splice(index);
                this.redraw();
            });
        });

        this.refs.fileStatusRemove.forEach(
            (fileStatusRemove: any, index: number) => {
                this.addEventListener(
                    fileStatusRemove,
                    'click',
                    (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.abortUpload) {
                            this.abortUpload();
                        }
                        this.statuses.splice(index, 1);
                        if (!this.statuses.length) {
                            this.refs.fileDrop.removeAttribute('hidden');
                        }
                        this.redraw();
                    },
                );
            },
        );

        return super.attach(element);
    }

    checkCustomValidityBeforeUpload(currentFilesData: Record<string, unknown>) {
        const newInstance = new FileComponent(this.schema);

        newInstance.dataValue = [
            ...this.getValue(),
            ...Object.values(currentFilesData),
        ];

        return (
            Validator.checkComponent(
                newInstance,
                this.data,
                this.row,
                true,
                false,
            )?.filter(
                (item: { context: { validator: string } }) =>
                    item?.context?.validator === 'custom',
            )?.length === 0
        );
    }

    checkComponentCustomValidity(currentFilesData: Record<string, unknown>) {
        const newInstance = new FileComponent(this.schema);

        newInstance.dataValue = [
            ...this.getValue(),
            ...Object.values(currentFilesData),
        ];

        const check = Validator.checkComponent(
            newInstance,
            this.data,
            this.row,
            true,
            false,
        )?.filter(
            (item: { context: { validator: string } }) =>
                item?.context?.validator === 'custom',
        );
        const validations = check;

        return this.setCustomValidity(validations, true);
    }

    upload(files: Record<string, unknown>) {
        const isValid = this.checkCustomValidityBeforeUpload(files);

        if (!isValid) {
            this.checkComponentCustomValidity(files);
        } else {
            super.upload(files);
        }
    }
}

export default File;
